<!-- @format -->

<template>
    <div class="SignIn">
        <div class="BG#FFF signup-wrapper" v-if="!signUpSuccess">
            <img src="@/assets/images/logo.svg" alt="" class="logo" />

            <v-form class="signup-form" ref="form" v-model="valid" @submit.prevent="submit">
                <h2 class="headSignUp">Request Sign In Credential</h2>
                <p style="margin-top: 0px">Fill out the below information which will be used to create your sign in
                    credential.</p>

                <v-container>
                    <v-row>
                        <!-- v-if="this.form.statementId != 0" -->
                        <v-col  cols="12" sm="12" md="12" class="pb-0">
                            <small>COMPANY NAME</small>
                            <!-- class="disabled-company-field" -->
                            <div >
                                <!-- <span>{{ form.company_name }}</span> -->
                                <v-text-field placeholder="e.g. Shifl" filled v-model="form.company_name"
                                        :rules="companyNameRule" required hide-details="auto"></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <v-row>
                                <v-col cols="6" sm="6">
                                    <small>FIRST NAME</small>
                                    <v-text-field placeholder="e.g. John" filled v-model="form.first_name"
                                        :rules="firstNameRule" required hide-details="auto"></v-text-field>

                                </v-col>
                                <v-col cols="6" sm="6">
                                    <small>LAST NAME</small>
                                    <v-text-field placeholder="e.g. Smith" filled v-model="form.last_name"
                                        :rules="lastNameRule" required hide-details="auto"></v-text-field>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <small>EMAIL ADDRESS</small>
                            <v-text-field placeholder="e.g. abcdefghij@email.com" filled required v-model="form.email"
                                :rules="emailRules" hide-details="auto"></v-text-field>

                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <small>PHONE NUMBER</small>
                            <vue-tel-input v-model="form.phone_number" mode="international" defaultCountry="us"
                                validCharactersOnly :autoDefaultCountry="true" :dropdownOptions="vueTelDropdownOptions"
                                :inputOptions="vueTelInputOptions" @input="onPhoneNumberInput"
                                :styleClasses="isPhoneNumberEmpty && form.phone_number.length < 11 ? 'is-error' : ''">

                                <template v-slot:arrow-icon>
                                    <v-icon class="ml-1">mdi-chevron-down</v-icon>
                                </template>
                            </vue-tel-input>

                            <span class="error-message" style="text-align: left; color: red; font-size: 12px;"
                                v-if="isPhoneNumberEmpty">
                                {{ isPhoneNumberEmpty ? 'Phone number must contain 11 digits' : '' }}
                            </span>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <small>PASSWORD</small>



                            <v-tooltip class="tooltip-box-with-shadow" color="#FFFFFF" right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-bind="attrs" v-on="on" placeholder="Type your password" filled
                                        v-model="form.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @input="handleInput" :rules="[rules.required]" :type="show1 ? 'text' : 'password'"
                                        name="input-10-1" hint="" @click:append="show1 = !show1"
                                        hide-details="auto"></v-text-field>

                                </template>

                                <div style="max-width: 210px;">


                                    <span style="color: #69758C; font-weight: 600; ">PASSWORD MUST CONTAIN</span>

                                    <div v-if="!this.passCondition1" class="tooltip-row"> <img
                                            src="@/assets/icons/error-cross.svg" alt="" />
                                        <span class="tooltip-content-error">at least 6 characters</span>
                                    </div>
                                    <div v-if="this.passCondition1" class="tooltip-row"> <img
                                            src="@/assets/icons/green-tick.svg" alt="" />
                                        <span class="tooltip-content-okay">at least 6 characters</span>
                                    </div>
                                    <div v-if="!this.passCondition2" class="tooltip-row"> <img
                                            src="@/assets/icons/error-cross.svg" alt="" />
                                        <span class="tooltip-content-error">at least one number</span>
                                    </div>
                                    <div v-if="this.passCondition2" class="tooltip-row"> <img
                                            src="@/assets/icons/green-tick.svg" alt="" />
                                        <span class="tooltip-content-okay">at least one number</span>
                                    </div>

                                    <div v-if="!this.passCondition3" class="tooltip-row"> <img
                                            src="@/assets/icons/error-cross.svg" alt="" />
                                        <span class="tooltip-content-error">at least one capital letter</span>
                                    </div>
                                    <div v-if="this.passCondition3" class="tooltip-row"> <img
                                            src="@/assets/icons/green-tick.svg" alt="" />
                                        <span class="tooltip-content-okay">at least one capital letter</span>
                                    </div>

                                    <div v-if="!this.passCondition4" class="tooltip-row"> <img
                                            src="@/assets/icons/error-cross.svg" alt="" />
                                        <span class="tooltip-content-error">at least one special character (e.g.
                                            @$#%&)</span>
                                    </div>
                                    <div v-if="this.passCondition4" class="tooltip-row"> <img
                                            src="@/assets/icons/green-tick.svg" alt="" />
                                        <span class="tooltip-content-okay">at least one special character (e.g.
                                            @$#%&)</span>
                                    </div>

                                </div>
                            </v-tooltip>


                        </v-col>


                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <v-btn class="submitFormBtn" text type="submit">
                                {{ form.signUpBtnValue }}
                            </v-btn>
                        </v-col>


                        <v-col v-show="getErrorMessage !== ''" cols="12" sm="12" class="flex">
                            <label class="error-message">
                                <img src="@/assets/images/error-alert.svg" alt="" />
                                <span class="error-text">{{ getErrorMessage }}.</span>
                            </label>
                        </v-col>


                    </v-row>
                </v-container>
            </v-form>
            <div class="snippet signup">
                <div class="stage">
                    <span class="ask-account-text">Already have a credential?
                        <router-link to="/login" style="font-weight: 600;
	color: #1A6D9E;">Sign In</router-link>
                    </span>
                </div>
            </div>
        </div>

        <div class="snippet" v-if="signUpSuccess">
            <div class="stage">
                <div class="dot-collision"></div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"
export default {
    components: {
        VueTelInput
    },
    data: () => ({
        valid: true,
        show1: false,
        showTooltip: false,
        isMobile: false,
        isPhoneNumberEmpty: false,
        passCondition1: false,
        passCondition2: false,
        passCondition3: false,
        passCondition4: false,

        form: {
            company_name: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            password: "",
            re_password: "",
            loading: false,
            redirectTo: '/signUpSuccess',
            signUpBtnValue: "Submit Request",
            signUpBtnDefaultValue: "Submit Request",
            signUpBtnLoadingValue: "Submiting Request...",
            statementId: 0,
            customerId: 0
        },

        vueTelDropdownOptions: {
            showDialCodeInSelection: true,
            showDialCodeInList: true,
            showFlags: true,
            showSearchBox: true,
        },
        vueTelInputOptions: {
            autocomplete: false,
            placeholder: "Type Phone Number",
            styleClasses: "tel-input-class",
            required: true,
            length: 11
        },
        countryCode: null,
        errors: [],
        rules: {
            required: (value) => !!value || "Password is required.",

            emailMatch: () => `Please provide a valid email address`,
        },
        emailRules: [
            (v) => !!v || "E-mail is required.",
            (v) => /.+@.+/.test(v) || "Invalid email address !",
        ],
        companyNameRule: [
            (v) => !!v || "Please enter company name",
        ],
        firstNameRule: [
            (v) => !!v || "Please enter first name",
        ],
        lastNameRule: [
            (v) => !!v || "Please enter last name",
        ],

        phoneNumberRule: [
            (v) => !!v || "Please enter phone number",
            (v) => !!v.length < 11 || "Phone number must contain 11 digits",
        ],
        signUpSuccess: false,
        makePaymentInfoDialog: false,
        requestSentDialog: false,
    }),
    computed: {
        ...mapGetters(["getErrorMessage"]),
    },
    methods: {
        ...mapActions(["signUp","setErrorMessage"]),
        validatePhoneNumber() {
            return this.form.phone_number !== '';
        },
        handleInput() {

            // v.length >= 6
            if (this.form.password.length > 5) {
                this.passCondition1 = true;
            } else {
                this.passCondition1 = false;
            }

            // atLeastOneNumb
            if (/\d/.test(this.form.password)) {
                this.passCondition2 = true;
            } else {
                this.passCondition2 = false;
            }

            // atLeastOneCapLetter
            if (/[A-Z]/.test(this.form.password)) {
                this.passCondition3 = true;
            } else {
                this.passCondition3 = false;
            }


            // atLeastOneSpecialCharacter
            if (/[!@#$%^&*(),.?":{}|<>]/.test(this.form.password)) {
                this.passCondition4 = true;
            } else {
                this.passCondition4 = false;
            }

        },
        onPhoneNumberInput(value) {
            // Apply input rules as needed
            // For example, you can limit the length of the phone number

            if (value.length > 10) {
                this.isPhoneNumberEmpty = false
            }
        },
        async submit() {

            var phoneNumber = this.form.phone_number
            this.form.phone_number = phoneNumber.replace(/\s+/g, '')
            const rePassword = this.form.password;
            this.form.re_password = rePassword
            this.isPhoneNumberEmpty = this.form.phone_number.length < 11 ? true : false;

            this.$refs.form.validate()
            if ((this.isPhoneNumberEmpty || !this.passCondition1 || !this.passCondition2 || !this.passCondition3 || !this.passCondition4) || !this.$refs.form.validate()) {
             
                this.setErrorMessage("Error: Please check the form details, then try again.")
                return
            }


            if (this.$refs.form.validate()) {
                //make sure to lock the login button to prevent multiple login clicks
                if (!this.form.loading) {
                    //set loading state
                    this.form.loading = true;
                    this.form.signUpBtnValue = this.form.signUpBtnLoadingValue;

                    //attempt to signup
                    try {

                        await this.signUp(this.form);
                        this.form.loading = false;
                        this.form.signUpBtnValue = this.form.signUpBtnDefaultValue;

                        if (this.getErrorMessage == "") {
                            this.signUpSuccess = true;
                        } else {
                            this.signUpSuccess = false;
                        }
                    } catch (e) {
                        this.form.loading = false;
                        this.form.signUpBtnValue = this.form.signUpBtnDefaultValue;
                        this.signUpSuccess = false;
                    }
                }
            }
        },

        closeRequestSentDialog() {
            this.requestSentDialog = false;
        },
    },
    created() {
        this.isMobile = this.$vuetify.breakpoint.mobile;
    },
    mounted() {

        const statementId = this.$route.query.statementId;
        const companyName = this.$route.query.companyName;
        const customerId = this.$route.query.customerId;
        this.form.customerId = customerId;
        this.form.statementId = statementId
        this.form.company_name = companyName


    },
    updated() { },
};
</script>

<style>
.signup-wrapper .error-message {
    display: flex;
    justify-content: left;
    align-items: flex-start;
}

.signup-wrapper .error-message img {
    margin-top: 3px;
}

.signup-wrapper .error-message .error-text {
    font-size: 10px;
    text-align: left;
    color: red;
    padding-left: 10px;
    margin-bottom: 10px;
}



.signup-wrapper .submitFormBtn span {
    font-family: "Inter-Regular", sans-serif !important;
}

/* .login-wrapper .col-6,
.login-wrapper .col-12:not(:last-of-type) {
    padding-bottom: 0;
} */

.dot-collision {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: #9880ff;
    color: #9880ff;
}

.dot-collision::before,
.dot-collision::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-collision::before {
    left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotCollisionAfter 2s infinite ease-in;
    animation-delay: 1s;
}

@keyframes dotCollisionBefore {

    0%,
    50%,
    75%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-15px);
    }
}

@keyframes dotCollisionAfter {

    0%,
    50%,
    75%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(15px);
    }
}

.signup {
    /* margin-top: 23px; */
    /* Frame 99 */

    /* Auto Layout */
    /* display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: relative;
    width: 218px;
    height: 20px;
    left: 611px;
    top: 668px; */

    margin-top: 16px;
}

.sign-text {
    /* Sign Up */
    text-decoration: none;
    position: static;
    width: 53px;
    height: 20px;
    left: 165px;
    top: 0px;

    /* H5 - 14M */
    /* font-family: Inter;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: right;

    /* Dark Blue */
    color: #0171a1;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
}

.ask-account-text {
    /* Don’t have an account? */

    position: static;
    width: 157px;
    height: 20px;
    left: 0px;
    top: 0px;

    /* H5 - 14M */
    /* font-family: Inter;
    font-style: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: right;

    /* Text Black */
    color: #253041;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}



.signup-form {
    width: 424px !important;
    /* height: 580px; */
}


.disabled-company-field {
    width: 100%;
    height: 45px;
    background-color: #F3F4F7;
    border: 1px solid #E7E9EE;
    border-radius: 4px;
    align-items: center;
    justify-content: start;
    display: flex;
    padding-inline: 8px;
}

.tooltip-custom {
    background-color: white;
}


.tooltip-box-with-shadow {
    background-color: lightgray;
    /* Set the background color to light gray */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Set a light gray box shadow */
    padding: 20px;
    /* Add padding for content */
    width: 200px;
    /* Set a width for the box */
}

.tooltip-row {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 3px;
}

.tooltip-content-error {
    color: #F93131;
    margin-left: 5px;
    font-size: 12px;
}

.tooltip-content-okay {
    color: #253041;
    margin-left: 5px;
    font-size: 12px;
}

.v-tooltip__content {

    background-color: lightgray;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 200px;
}

.headSignUp {
    font-weight: unset;
    font-family: "Inter-SemiBold", sans-serif !important;
    font-family: "Inter-Medium", sans-serif;
    font-size: 20px;
    line-height: 20px;
    padding: 24px 0 12px 0;
    color: #253041;
}


@media (max-width: 480px) {
    .signup-form {
        width: auto !important;
    }
}</style>
